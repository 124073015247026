<template>
  <div class="horizontal-social-space p-3">
    <scroller :scroll-size="200">
      <div class="d-flex social-space position-relative">
        <template v-for="(social, index) of space" :key="index">
          <div class="social-space-card clickable-item mr-2" @click="setActive(index)">
            <img
              v-image
              class="img clickable-item"
              :src="resizeUpload(social.cover_image, '240h')"
              :class="{
                active: selectedIndex === index,
                'no-border': selectedIndex !== index,
              }"
            />
            <ion-badge
              v-if="selectedIndex !== index && get(unread, `${social.id}.worldunread_msgs_count`)"
              class="top"
              color="danger"
            >
              <span v-if="get(unread, `${social.id}.worldunread_msgs_count`) >= 99">99+</span>
              <span v-else> {{ get(unread, `${social.id}.worldunread_msgs_count`) }}</span></ion-badge
            >
            <span v-if="social.privacy === 'M'" class="private"> 🔒 </span>
            <div
              v-if="social.user?.username === user.username"
              class="clickable drop-down d-flex align-items-center justify-content-center"
              @click.stop.prevent="openDropDown($event, social, index)"
            >
              <i class="ti-more-alt" />
            </div>
          </div>
        </template>
      </div>
    </scroller>
  </div>
</template>

<script lang="ts" setup>
import { popovers } from '@/shared/native/popovers';
import Scroller from '@/shared/components/Scroller.vue';
import CardPopover from '@/shared/components/storage/popovers/CardPopover.vue';
import { authStore } from '@/shared/pinia-store/auth';
import { resizeUpload } from '@/shared/utils/upload';

const props = defineProps({
  space: {
    type: Array,
  },
  unread: {
    type: Object,
    required: true,
  },
});

const { user } = authStore();
const space: any = toRef(props, 'space') as any;
const emits = defineEmits(['isactive']);
const selectedIndex: any = ref(0);

const setActive = (index: any) => {
  selectedIndex.value = index;
  emits('isactive', selectedIndex.value);
};

const openDropDown = async (ev: CustomEvent, social: any, index: any) => {
  setActive(index);
  await popovers.open(ev, CardPopover, { title: 'Social Space', id: get(social, 'id'), value: 'world' });
};
</script>
<style lang="sass" scoped>
.drop-down
  position: absolute
  z-index: 2
  right: 8px
  width: 20px
  height: 20px
  color: #FFF
  background: #0A0928E5
  font-size: 12px
  border-radius: 6px
  bottom: 14px
.private
  font-size: 15px
  position: absolute
  left: 10px
  top: 9px

.top
  border-radius: 8px
  font-size: 12px
  width: 45px
  position: relative
  right: -95px
  top: -174px
  @media(max-width: 500px)
    top: -154px !important
.horizontal-social-space
  overflow: unset
  @media(max-width: 1320px)
    overflow-x: auto

.img
  width: 250px
  height: 170px
  object-fit: cover
  border-radius: 10px
  @media(max-width: 500px)
    height: 150px !important

.social-space
  overflow-x: unset

.social-space::-webkit-scrollbar
  display: none
.social-space
  -ms-overflow-style: none
  scrollbar-width: none
.active
  pointer-events: none
  border: 5px solid #df16d6
  border-top-color: #af37e4
  border-right-color: #af37e4

.no-border
  border: none
.social-space-card
  position: relative
  overflow: unset
  border-radius: 20px
  min-width: 140px !important
  max-width: 140px !important
</style>
